import * as React from "react";

import type { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid/DataGrid";

export interface GridRowBaseDef {
    id: number | null;
    autoCompleteValue?: string;
}

interface Props {
    rows: GridRowBaseDef[];
    cols: GridColDef[];
    initialSortField: string;
    handleOnRowClick?: (row: GridRowParams) => void;
}

export default function BaseTable({
    rows,
    cols,
    initialSortField,
    handleOnRowClick = () => {},
}: Props): React.JSX.Element {
    return (
        <DataGrid
            autoHeight
            columnHeaderHeight={80}
            rows={rows}
            columns={cols}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 50,
                    },
                },
                sorting: {
                    sortModel: [{ field: initialSortField, sort: "asc" }],
                },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            onRowClick={handleOnRowClick}
            sortingOrder={["asc", "desc"]}
            slotProps={{
                filterPanel: {
                    sx: {
                        ".MuiDataGrid-filterFormColumnInput": {
                            maxWidth: "60px",
                        },
                        ".MuiDataGrid-filterFormOperatorInput": {
                            maxWidth: "90px",
                        },
                        ".MuiDataGrid-filterFormValueInput": {
                            maxWidth: "90px",
                        },
                    },
                },
                panel: {
                    sx: {
                        top: "-65px !important",
                    },
                },
                footer: {
                    sx: {
                        ".MuiDataGrid-selectedRowCount": {
                            visibility: "hidden",
                            width: 0,
                            height: 0,
                            padding: 0,
                            m: 0,
                        },
                        ".MuiTablePagination-root .MuiTablePagination-input": {
                            display: "inline-flex",
                        },
                        display: "flex",
                        justifyContent: "center",
                    },
                },
                baseTooltip: {
                    sx: {},
                },
            }}
            sx={{
                ".MuiDataGrid-columnHeader": {
                    p: 0.5,
                },
                ".MuiDataGrid-columnSeparator": {
                    display: "none",
                },
                "&.MuiDataGrid-root": {
                    border: "none",
                },
            }}
            data-testid="PartnerTable_dataGrid"
        />
    );
}
