import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import TuneIcon from "@mui/icons-material/Tune";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";

import type { SettingsStat } from "../types";

interface Props {
    settingsSets: SettingsStat;
    handleSettingsSetsChange: (set: SettingsStat) => void;
}

export default function MenuSettings({
    settingsSets,
    handleSettingsSetsChange,
}: Props) {
    const [open, setOpen] = React.useState(false);
    const [maybeSettingsSets, setMaybeSettingsSets] =
        React.useState(settingsSets);

    const handleSettingsClose = () => {
        setMaybeSettingsSets(settingsSets);
        setOpen(false);
    };

    const handleSubmit = () => {
        setOpen(false);
    };

    const handleMaybeSettingsSetsChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setMaybeSettingsSets(event.target.value as SettingsStat);
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                m: 0,
                p: 0,
            }}
            component="div"
        >
            <Dialog open={open} onClose={handleSettingsClose} scroll="body">
                <DialogTitle>Settings</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleSettingsClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (t) => t.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ p: 3, minWidth: 275 }}>
                    <Stack spacing={3}>
                        <FormControl>
                            <FormLabel>Sets</FormLabel>
                            <RadioGroup
                                defaultValue="all"
                                value={maybeSettingsSets}
                                onChange={handleMaybeSettingsSetsChange}
                            >
                                <FormControlLabel
                                    value="all"
                                    control={<Radio />}
                                    label="All"
                                />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="2 Sets"
                                />
                                <FormControlLabel
                                    value="3"
                                    control={<Radio />}
                                    label="3 Sets"
                                />
                            </RadioGroup>
                        </FormControl>
                        <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                            component="div"
                        >
                            <Button
                                color="secondary"
                                variant="contained"
                                sx={{ minWidth: 150 }}
                                onClick={() => {
                                    handleSettingsSetsChange(maybeSettingsSets);
                                    handleSubmit();
                                }}
                            >
                                Apply
                            </Button>
                        </Box>
                    </Stack>
                </DialogContent>
            </Dialog>
            <TuneIcon onClick={() => setOpen(true)} />
        </Box>
    );
}
