import * as React from "react";
import { Line } from "react-chartjs-2";

import {
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-date-fns";

import { DataSet } from "../types";

ChartJS.register(
    CategoryScale,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeSeriesScale,
    TimeScale,
    Title,
    Tooltip,
    zoomPlugin,
);

interface Props {
    dataSets: DataSet[];
}

export default function LineChart({ dataSets }: Props): React.JSX.Element {
    const options: ChartOptions<"line"> = {
        interaction: {
            mode: "index",
            intersect: true,
        },
        elements: {
            point: {
                radius: 4.5,
            },
        },
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                labels: {
                    font: {
                        size: 20,
                    },
                },
            },
            tooltip: {
                titleFont: {
                    size: 20,
                },
                bodyFont: {
                    size: 20,
                },
            },
            zoom: {
                zoom: {
                    drag: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    wheel: {
                        enabled: true,
                    },
                    mode: "x",
                },
                pan: {
                    enabled: true,
                    modifierKey: "shift",
                    mode: "x",
                },
                limits: {
                    x: { min: "original", max: "original" },
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    minRotation: 45,
                    autoSkip: true,
                    maxTicksLimit: 15,
                    stepSize: 1,
                    font: {
                        size: 12,
                    },
                    source: "data",
                    includeBounds: true,
                },
                grid: {
                    display: false,
                },
                type: "timeseries",
                time: {
                    unit: "day",
                    displayFormats: {
                        day: "MMM dd, yyyy",
                    },
                    tooltipFormat: "M/dd/yyyy hh:mm aa",
                },
            },
            y: {
                display: true,
                position: "left",
                ticks: { font: { size: 15 } },
                reverse: true,
                type: "linear",
            },
        },
    };

    return (
        <div style={{ height: "28em" }}>
            <Line
                data={{ datasets: dataSets }}
                options={options}
                id="pti_line_chart"
            />
        </div>
    );
}

export function resetZoom() {
    const lineChart = ChartJS.getChart("pti_line_chart");
    lineChart?.resetZoom();
}
