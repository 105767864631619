import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import { MatchUnified } from "@volley/data";

import { terseDateFormat } from "../../../util";
import { DataSet } from "../types";
import {
    getDateByUtc,
    getMatchesAsc,
    transformToCoordinates,
} from "../utils/util";

import DoubleSlider from "./DoubleSlider";
import LineChart, { resetZoom } from "./LineChart";

export const PTI_LABEL = "pti";

interface Props {
    dataSets: DataSet[];
    matches: MatchUnified[];
    playerId: number;
    setDataSets: React.Dispatch<React.SetStateAction<DataSet[]>>;
}

export default function PlayerPTIGrid({
    dataSets,
    matches,
    playerId,
    setDataSets,
}: Props): React.JSX.Element {
    const matchesAscOrder = getMatchesAsc(matches);

    return (
        <Stack spacing={1} data-testid="LineChartGrid_grid">
            <LineChart dataSets={dataSets} />
            <DoubleSlider
                minDistance={15}
                minValue={0}
                maxValue={matchesAscOrder.length - 1}
                handleChange={(range) =>
                    setDataSets((current) => {
                        if (current.length <= 0) {
                            return current;
                        }

                        const ptiDataSet = current.find(
                            (ds) => ds.label === PTI_LABEL,
                        );

                        if (!ptiDataSet?.data) {
                            return [...current];
                        }

                        const trimmedData = transformToCoordinates(
                            matchesAscOrder.slice(range[0], range[1] + 1),
                            playerId,
                        );

                        ptiDataSet.data = trimmedData;
                        return [...current];
                    })
                }
                valueLabelFormat={(currentValue: number) => {
                    if (currentValue < 0) {
                        return "";
                    }

                    if (matchesAscOrder.length === 0) {
                        return "";
                    }

                    return terseDateFormat(
                        new Date(
                            getDateByUtc(matchesAscOrder[currentValue].date),
                        ),
                    );
                }}
            />
            <Box component="div" display="flex" justifyContent="center">
                <Button
                    size="small"
                    onClick={() => resetZoom()}
                    sx={{ mt: 1 }}
                    variant="contained"
                >
                    Reset Zoom
                </Button>
            </Box>
        </Stack>
    );
}
