import * as React from "react";

import CheckIcon from "@mui/icons-material/Check";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PlaceIcon from "@mui/icons-material/Place";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { MatchUnified } from "@volley/data";

import { terseDateFormat } from "../../../util";
import { getPlayerSide, getWinner } from "../utils/labels";
import {
    buildMatchKey,
    formatTwoDecimalWithSign,
    getDateByUtc,
    getMatchesDesc,
    getRecord,
} from "../utils/util";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

export function getPlayerPtiChange(match: MatchUnified, playerId: number) {
    const playerSide = getPlayerSide(match, playerId);

    if (!playerSide) {
        return NaN;
    }

    const ptiStartKey =
        `${playerSide.player}_${playerSide.side}_elo_start` as keyof MatchUnified;
    const ptiEndKey =
        `${playerSide.player}_${playerSide.side}_elo_end` as keyof MatchUnified;

    const ptiStart = match[ptiStartKey] as number;
    const ptiEnd = match[ptiEndKey] as number;
    return ptiEnd - ptiStart;
}

function calculateCummulativePti(matches: MatchUnified[], playerId: number) {
    let ptiCummulative = 0;
    matches.forEach((match) => {
        const delta = getPlayerPtiChange(match, playerId);
        ptiCummulative += delta;
    });

    return ptiCummulative;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0 }} component="div">
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </Box>
    );
}

export function MatchRow({
    match,
    playerId,
}: {
    match: MatchUnified;
    playerId: number;
}): React.JSX.Element {
    return (
        <div key={buildMatchKey(match)}>
            <Stack direction="row" spacing={0} sx={{ m: 1 }}>
                <Grid
                    container
                    spacing={0.8}
                    alignItems="center"
                    display="flex"
                    sx={{ mb: 3 }}
                >
                    <Grid size={{ xs: 1 }}>
                        <PlaceIcon fontSize="small" />
                    </Grid>
                    <Grid size={{ xs: 11 }}>
                        <Typography variant="h5" component="span">
                            {match.team_1_club_name ||
                                match.tournament_name ||
                                "n/a"}
                        </Typography>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Typography variant="body2" component="span">
                            {terseDateFormat(getDateByUtc(match.date))}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        spacing={1.5}
                        sx={{
                            border: `2px solid ${getWinner(match.winner) === "home" ? "#2e7d32" : "#000000"}`,
                            borderRadius: "7px",
                            borderWidth: "thin",
                            width: "100%",
                            m: 0,
                            mb: 1,
                        }}
                    >
                        <Grid size={{ xs: 8.5 }}>
                            <Typography variant="body2" component="span">
                                <Stack direction="column">
                                    <span>
                                        {match.player_1_home_name}
                                        &nbsp; (H)
                                    </span>
                                    <span>
                                        {match.player_2_home_name}
                                        &nbsp; (H)
                                    </span>
                                </Stack>
                            </Typography>
                        </Grid>
                        <Grid size={{ xs: 3.5 }} sx={{ mt: 0.5 }}>
                            <span>
                                {match.set_1_team_1_score}
                                &nbsp;
                                {match.set_2_team_1_score}
                                &nbsp;
                                {match.set_3_team_1_score}
                                {match.set_4_team_1_score && (
                                    <>
                                        &nbsp;
                                        {match.set_4_team_1_score}
                                    </>
                                )}
                                {match.set_5_team_1_score && (
                                    <>
                                        &nbsp;
                                        {match.set_5_team_1_score}
                                    </>
                                )}
                            </span>
                            <span>
                                {getWinner(match.winner) === "home" && (
                                    <CheckIcon
                                        color="success"
                                        fontSize="medium"
                                    />
                                )}
                            </span>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1.5}
                        sx={{
                            border: `2px solid ${getWinner(match.winner) === "away" ? "#2e7d32" : "#000000"}`,
                            borderRadius: "7px",
                            borderWidth: "thin",
                            width: "100%",
                            margin: 0,
                        }}
                    >
                        <Grid size={{ xs: 8.5 }}>
                            <Typography variant="body2" component="span">
                                <Stack direction="column">
                                    <span>
                                        {match.player_1_away_name}
                                        &nbsp; (A)
                                    </span>
                                    <span>
                                        {match.player_2_away_name}
                                        &nbsp; (A)
                                    </span>
                                </Stack>
                            </Typography>
                        </Grid>
                        <Grid size={{ xs: 3.5 }} sx={{ mt: 0.5 }}>
                            <span>
                                {match.set_1_team_2_score}
                                &nbsp;
                                {match.set_2_team_2_score}
                                &nbsp;
                                {match.set_3_team_2_score}
                                {match.set_4_team_2_score && (
                                    <>
                                        &nbsp;
                                        {match.set_4_team_2_score}
                                    </>
                                )}
                                {match.set_5_team_2_score && (
                                    <>
                                        &nbsp;
                                        {match.set_5_team_2_score}
                                    </>
                                )}
                            </span>
                            <span>
                                {getWinner(match.winner) === "away" && (
                                    <CheckIcon
                                        color="success"
                                        fontSize="medium"
                                    />
                                )}
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                        minWidth: 30,
                        ml: 1,
                        mt: 1,
                    }}
                    component="div"
                >
                    <span style={{ marginTop: 25 }}>
                        {formatTwoDecimalWithSign(
                            getPlayerPtiChange(match, playerId),
                        )}
                    </span>
                </Box>
            </Stack>
        </div>
    );
}

interface Props {
    matches: MatchUnified[];
    playerId: number;
    showRecord?: boolean;
}

export default function MatchesTable({
    matches,
    playerId,
    showRecord = false,
}: Props) {
    const matchesDesc = getMatchesDesc(matches);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const matchesRecord = getRecord(matches, playerId);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            {showRecord && (
                <>
                    <Box
                        component="div"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <Stack>
                            <Typography variant="h3">
                                {`${matchesRecord.wins}-${matchesRecord.losses}-${matchesRecord.ties}`}
                            </Typography>
                            <Typography variant="body2">
                                win-loss-tie
                            </Typography>
                        </Stack>
                    </Box>
                    <Divider sx={{ m: 3, ml: 5, mr: 5 }} />
                </>
            )}
            <Box
                sx={{ display: "flex", justifyContent: "flex-end" }}
                component="div"
            >
                <span style={{ fontStyle: "italic" }}>cumulative</span>
                &nbsp;&nbsp;
                {formatTwoDecimalWithSign(
                    calculateCummulativePti(matches, playerId),
                )}
            </Box>
            {(rowsPerPage > 0
                ? matchesDesc.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                  )
                : matchesDesc
            ).map((match) => MatchRow({ match, playerId }))}
            <Divider sx={{ mt: 2 }} />
            <Table sx={{ overflowY: "hidden" }}>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            ActionsComponent={TablePaginationActions}
                            count={matchesDesc.length}
                            labelRowsPerPage=""
                            page={page}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                50,
                                100,
                                { label: "All", value: -1 },
                            ]}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                ".MuiInputBase-root": {
                                    marginRight: 1,
                                    marginLeft: 0,
                                },
                                ".MuiButtonBase-root": {
                                    padding: 0.5,
                                },
                                ".MuiTablePagination-toolbar": {
                                    padding: 0,
                                },
                                borderBottom: "none",
                            }}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </>
    );
}
