import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import type { GridColDef, GridRowParams } from "@mui/x-data-grid";

import { ClubLocationStats, MatchId, MatchUnified } from "@volley/data";

import { fetchApi } from "../../../util";
import TableAutoComplete from "../AutoComplete/TableAutoComplete";
import MatchesTable from "../Matches/MatchesTable";

import BaseTable, { GridRowBaseDef } from "./BaseTable";

interface LocationRow extends GridRowBaseDef {
    clubName: string | null;
    locationMatchCount: number;
    loss: number;
    matches: MatchId[];
    win: number;
    winPercentage: number;
}

interface Props {
    matches: MatchUnified[];
    playerId: number;
}

export default function LocationTable({
    matches,
    playerId,
}: Props): React.JSX.Element {
    const [open, setOpen] = React.useState(false);

    // table rows
    const [rows, setRows] = React.useState<LocationRow[]>([]);
    const [selectedLocationRow, setSelectedPartnerRow] =
        React.useState<LocationRow | null>(null);
    const [autoCompleteInputs, setAutoCompleteInputs] = React.useState<
        GridRowBaseDef[] | null
    >(null);

    // miscellaneous
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState<string[]>([]);

    React.useEffect(() => {
        setLoading(true);
        fetchApi<ClubLocationStats[]>("/api/pti/locations-stats", "GET")
            .then((response) => {
                setRows(
                    response.map((resp, i) => ({
                        autoCompleteValue: resp.club_name,
                        id: i,
                        clubName: resp.club_name,
                        locationMatchCount: resp.total_matches,
                        loss: resp.losses,
                        matches: resp.matches,
                        win: resp.wins,
                        winPercentage: parseFloat(
                            ((resp.wins / resp.total_matches) * 100).toFixed(1),
                        ),
                    })),
                );

                setLoading(false);
            })
            .catch((error: Error) =>
                setErrors((currentErrors) => {
                    currentErrors.push(error.message);
                    return [...currentErrors];
                }),
            );
    }, [playerId]);

    const cols: GridColDef[] = [
        {
            field: "clubName",
            headerName: "Location",
            align: "left",
            sortable: true,
            disableColumnMenu: true,
            width: 140,
        },
        {
            field: "locationMatchCount",
            headerName: "#",
            align: "left",
            sortable: true,
            disableColumnMenu: true,
            width: 1,
        },
        {
            field: "win",
            headerName: "W",
            align: "left",
            sortable: true,
            width: 1,
            disableColumnMenu: true,
        },
        {
            field: "loss",
            headerName: "L",
            align: "left",
            sortable: true,
            width: 1,
            disableColumnMenu: true,
        },
        {
            field: "winPercentage",
            headerName: "%",
            align: "left",
            sortable: true,
            width: 1,
            disableColumnMenu: true,
        },
    ];

    const handleOnRowClick = (e: GridRowParams<LocationRow>) => {
        const { row } = e;
        setSelectedPartnerRow(row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (errors.length > 0) {
        return (
            <Alert
                severity="error"
                data-testid="LocationsTable_errorAlert"
                sx={{ m: 2 }}
            >
                <AlertTitle>Error</AlertTitle>
                <ul>
                    {errors.map((item) => (
                        <li key={item}>{item}</li>
                    ))}
                </ul>
            </Alert>
        );
    }

    if (loading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "50vh",
                }}
            >
                <CircularProgress size={50} thickness={4} />
            </div>
        );
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} scroll="body">
                <DialogTitle>Location Matches</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ pl: 1, pr: 1 }}>
                    <MatchesTable
                        matches={matches.filter((match) => {
                            if (!selectedLocationRow) {
                                return false;
                            }

                            return selectedLocationRow.matches.find(
                                (rowMatch) =>
                                    rowMatch.date === match.date &&
                                    rowMatch.line_number ===
                                        match.line_number &&
                                    rowMatch.schedule_id === match.schedule_id,
                            );
                        })}
                        playerId={playerId}
                    />
                </DialogContent>
            </Dialog>
            <Box style={{ marginBottom: 5 }} component="div">
                <TableAutoComplete
                    label="search locations"
                    initialValues={autoCompleteInputs || []}
                    selectionOptions={rows}
                    handleSelectValues={setAutoCompleteInputs}
                    multiple
                />
            </Box>
            <BaseTable
                rows={rows.filter((row) => {
                    if (autoCompleteInputs && autoCompleteInputs.length > 0) {
                        return autoCompleteInputs
                            .map((selection) => selection.autoCompleteValue)
                            .includes(row.autoCompleteValue);
                    }

                    return true;
                })}
                cols={cols}
                initialSortField="clubName"
                handleOnRowClick={handleOnRowClick}
            />
        </>
    );
}
