import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import type { GridColDef, GridRowParams } from "@mui/x-data-grid";

import { MatchUnified } from "@volley/data";

import AutoCompleteDateWrapper from "../AutoComplete/AutoCompleteDateWrapper";
import MatchesTable from "../Matches/MatchesTable";
import {
    getOpponents,
    getOppositeSide,
    getPlayerKey,
    getPlayerName,
    getWinner,
    isOpponent,
} from "../utils/labels";
import { withinTimeFilter } from "../utils/util";

import BaseTable, { GridRowBaseDef } from "./BaseTable";

interface OpponentRow extends GridRowBaseDef {
    opponentName: string | null;
    opponentMatchCount: number;
    loss: number;
    win: number;
    winPercentage: number;
}

type OpponentStat = Record<
    string,
    {
        numMatches: number;
        numWin: number;
        numLoss: number;
        opponentId: number;
        opponentName: string;
    }
>;

interface Props {
    matches: MatchUnified[];
    playerId: number;
}

export default function OpponentTable({
    matches,
    playerId,
}: Props): React.JSX.Element {
    const [open, setOpen] = React.useState(false);

    // table selection
    const [selectedOppRow, setSelectedOppRow] =
        React.useState<OpponentRow | null>(null);

    // filters
    const [autoCompleteInputs, setAutoCompleteInputs] = React.useState<
        GridRowBaseDef[] | null
    >(null);
    const [daysAgo, setDaysAgo] = React.useState(NaN);

    const filteredMatched = React.useMemo(
        () => matches.filter((match) => withinTimeFilter(match, daysAgo)),
        [daysAgo, matches],
    );

    const dialogMatches = React.useMemo(
        () =>
            filteredMatched.filter(
                (match) =>
                    selectedOppRow?.id &&
                    isOpponent(match, playerId, selectedOppRow.id),
            ),
        [filteredMatched, playerId, selectedOppRow?.id],
    );

    const rows: OpponentRow[] = React.useMemo(
        () =>
            Object.values(
                filteredMatched.reduce<OpponentStat>((acc, match) => {
                    const opponents = getOpponents(match, playerId);
                    opponents.forEach((opponent) => {
                        if (!opponent) return;
                        // this dynamically targets player ids which are numbers
                        const opponentId = match[
                            getPlayerKey(opponent)
                        ] as number;
                        // this dynamically targets player names which are string
                        const opponentName = match[
                            getPlayerName(opponent)
                        ] as string;

                        if (!(opponentId in acc)) {
                            acc[opponentId] = {
                                opponentId,
                                opponentName,
                                numMatches: 0,
                                numWin: 0,
                                numLoss: 0,
                            };
                        }

                        acc[opponentId].numMatches += 1;

                        if (getWinner(match.winner) === opponent.side) {
                            acc[opponentId].numLoss += 1;
                        }

                        if (
                            getWinner(match.winner) ===
                            getOppositeSide(opponent.side)
                        ) {
                            acc[opponentId].numWin += 1;
                        }
                    });
                    return acc;
                }, {}),
            )
                .map((stat) => ({
                    autoCompleteValue: stat.opponentName.replace(/\./g, ""),
                    id: stat.opponentId,
                    opponentName: stat.opponentName.replace(/\./g, ""),
                    opponentMatchCount: stat.numMatches,
                    loss: stat.numLoss,
                    win: stat.numWin,
                    winPercentage: parseFloat(
                        ((stat.numWin / stat.numMatches) * 100).toFixed(1),
                    ),
                }))
                .sort((a, b) => {
                    if (!a.opponentName || !b.opponentName) {
                        return 1;
                    }

                    return a.opponentName.localeCompare(b.opponentName);
                }),
        [filteredMatched, playerId],
    );

    const cols: GridColDef[] = React.useMemo(
        () => [
            {
                field: "opponentName",
                headerName: "Opponent",
                align: "left",
                sortable: true,
                disableColumnMenu: true,
                width: 120,
                renderCell: (params) => {
                    const row = params.row as OpponentRow;
                    if (!row.opponentName) {
                        return "n/a";
                    }

                    return row.opponentName;
                },
            },
            {
                field: "opponentMatchCount",
                headerName: "#",
                align: "left",
                sortable: true,
                disableColumnMenu: true,
                width: 1,
            },
            {
                field: "win",
                headerName: "W",
                align: "left",
                sortable: true,
                width: 1,
                disableColumnMenu: true,
            },
            {
                field: "loss",
                headerName: "L",
                align: "left",
                sortable: true,
                width: 1,
                disableColumnMenu: true,
            },
            {
                field: "winPercentage",
                headerName: "%",
                align: "left",
                sortable: true,
                width: 1,
                disableColumnMenu: true,
            },
        ],
        [],
    );

    const handleOnRowClick = (e: GridRowParams<OpponentRow>) => {
        const { row } = e;
        setSelectedOppRow(row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} scroll="body">
                <DialogTitle>Opponent Matches</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ pl: 1, pr: 1 }}>
                    <MatchesTable matches={dialogMatches} playerId={playerId} />
                </DialogContent>
            </Dialog>
            <Box style={{ marginBottom: 5 }} component="div">
                <AutoCompleteDateWrapper
                    label="Search opponents"
                    handleDaysFilter={setDaysAgo}
                    initialValues={autoCompleteInputs || []}
                    selectionOptions={rows}
                    handleSelectValues={setAutoCompleteInputs}
                    multiple
                />
            </Box>
            <BaseTable
                rows={rows.filter((row) => {
                    if (autoCompleteInputs && autoCompleteInputs.length > 0) {
                        return autoCompleteInputs
                            .map((input) => input.autoCompleteValue)
                            .includes(row.autoCompleteValue);
                    }

                    return true;
                })}
                cols={cols}
                initialSortField="opponentName"
                handleOnRowClick={handleOnRowClick}
            />
        </>
    );
}
