import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import type { GridColDef, GridRowParams } from "@mui/x-data-grid";

import { MatchUnified } from "@volley/data";

import AutoCompleteDateWrapper from "../AutoComplete/AutoCompleteDateWrapper";
import MatchesTable, { getPlayerPtiChange } from "../Matches/MatchesTable";
import {
    getOppositeSide,
    getPartner,
    getPlayerKey,
    getPlayerName,
    getWinner,
    isPartner,
} from "../utils/labels";
import { formatTwoDecimalWithSign, withinTimeFilter } from "../utils/util";

import BaseTable, { GridRowBaseDef } from "./BaseTable";

interface PartnerRow extends GridRowBaseDef {
    partnerName: string | null;
    partnerMatchCount: number;
    pti: number;
    loss: number;
    win: number;
    winPercentage: number;
}

type PartnerStat = Record<
    string,
    {
        numMatches: number;
        numWin: number;
        numLoss: number;
        partnerId: number;
        partnerName: string;
        pti: number;
    }
>;

interface Props {
    matches: MatchUnified[];
    playerId: number;
}

export default function PartnerTable({
    matches,
    playerId,
}: Props): React.JSX.Element {
    const [open, setOpen] = React.useState(false);

    // table selection
    const [selectedPartnerRow, setSelectedPartnerRow] =
        React.useState<PartnerRow | null>(null);

    // filters
    const [autoCompleteInputs, setAutoCompleteInputs] = React.useState<
        GridRowBaseDef[] | null
    >(null);
    const [daysAgo, setDaysAgo] = React.useState(NaN);

    const filteredMatched = React.useMemo(
        () => matches.filter((match) => withinTimeFilter(match, daysAgo)),
        [daysAgo, matches],
    );

    const rows: PartnerRow[] = React.useMemo(
        () =>
            Object.values(
                filteredMatched.reduce<PartnerStat>((acc, match) => {
                    const partner = getPartner(match, playerId);
                    if (!partner) return acc;
                    // this dynamically targets player ids which are numbers
                    const partnerId = match[getPlayerKey(partner)] as number;
                    // this dynamically targets player names which are strings
                    const partnerName = match[getPlayerName(partner)] as string;

                    if (!(partnerId in acc)) {
                        acc[partnerId] = {
                            partnerId,
                            partnerName,
                            pti: 0,
                            numMatches: 0,
                            numWin: 0,
                            numLoss: 0,
                        };
                    }

                    acc[partnerId].numMatches += 1;
                    acc[partnerId].pti += getPlayerPtiChange(match, playerId);

                    if (getWinner(match.winner) === partner.side) {
                        acc[partnerId].numWin += 1;
                    }

                    if (
                        getWinner(match.winner) ===
                        getOppositeSide(partner.side)
                    ) {
                        acc[partnerId].numLoss += 1;
                    }
                    return acc;
                }, {}),
            )
                .map((stat) => ({
                    autoCompleteValue: stat.partnerName.replace(/\./g, ""),
                    id: stat.partnerId,
                    partnerName: stat.partnerName.replace(/\./g, ""),
                    partnerMatchCount: stat.numMatches,
                    pti: stat.pti,
                    loss: stat.numLoss,
                    win: stat.numWin,
                    winPercentage: parseFloat(
                        ((stat.numWin / stat.numMatches) * 100).toFixed(1),
                    ),
                }))
                .sort((a, b) => {
                    if (!a.partnerName || !b.partnerName) {
                        return 1;
                    }

                    return a.partnerName.localeCompare(b.partnerName);
                }),
        [filteredMatched, playerId],
    );

    const cols: GridColDef[] = React.useMemo(
        () => [
            {
                field: "partnerName",
                headerName: "Partner",
                align: "left",
                sortable: true,
                disableColumnMenu: true,
                width: 120,
                renderCell: (params) => {
                    const row = params.row as PartnerRow;

                    if (!row.partnerName) {
                        return <span>n/a</span>;
                    }
                    return row.partnerName;
                },
            },
            {
                field: "partnerMatchCount",
                headerName: "#",
                align: "left",
                sortable: true,
                disableColumnMenu: true,
                width: 1,
            },
            {
                field: "win",
                headerName: "W",
                align: "left",
                sortable: true,
                width: 1,
                disableColumnMenu: true,
            },
            {
                field: "loss",
                headerName: "L",
                align: "left",
                sortable: true,
                width: 1,
                disableColumnMenu: true,
            },
            {
                field: "winPercentage",
                headerName: "%",
                align: "left",
                sortable: true,
                width: 1,
                disableColumnMenu: true,
            },
            {
                field: "pti",
                headerName: "PTI",
                align: "left",
                sortable: true,
                width: 56,
                disableColumnMenu: true,
                renderCell: (params) => {
                    const row = params.row as PartnerRow;
                    return <span>{formatTwoDecimalWithSign(row.pti)}</span>;
                },
            },
        ],
        [],
    );

    const handleOnRowClick = (e: GridRowParams<PartnerRow>) => {
        const { row } = e;
        setSelectedPartnerRow(row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} scroll="body">
                <DialogTitle>Partner Matches</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ pl: 1, pr: 1 }}>
                    <MatchesTable
                        matches={filteredMatched.filter((match) => {
                            if (
                                selectedPartnerRow?.id &&
                                isPartner(
                                    match,
                                    playerId,
                                    selectedPartnerRow.id,
                                )
                            ) {
                                return true;
                            }

                            return false;
                        })}
                        playerId={playerId}
                    />
                </DialogContent>
            </Dialog>
            <Box style={{ marginBottom: 5 }} component="div">
                <AutoCompleteDateWrapper
                    label="Search partner"
                    handleDaysFilter={setDaysAgo}
                    initialValues={autoCompleteInputs || []}
                    selectionOptions={rows}
                    handleSelectValues={setAutoCompleteInputs}
                    multiple
                />
            </Box>
            <BaseTable
                rows={rows.filter((row) => {
                    if (autoCompleteInputs && autoCompleteInputs.length > 0) {
                        return autoCompleteInputs
                            .map((input) => input.autoCompleteValue)
                            .includes(row.autoCompleteValue);
                    }

                    return true;
                })}
                cols={cols}
                initialSortField="partnerName"
                handleOnRowClick={handleOnRowClick}
            />
        </>
    );
}
